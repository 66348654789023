// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/nazli.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: nazli;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.contactUs{
    font-family: nazli;
    margin-right: 20%;
    margin-bottom: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Contact.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4CAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":["@font-face {\n    font-family: nazli;\n    src: url(../fonts/nazli.ttf);\n}\n.contactUs{\n    font-family: nazli;\n    margin-right: 20%;\n    margin-bottom: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
