import React from 'react';
import  './Footer.css'

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 فروشگاه اینترنتی رویا. تمامی حقوق محفوظ است.</p>
    </footer>
  );
}

export default Footer;