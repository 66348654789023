import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css'

function Header() {
  return (
    <header className="header">
      <div className="storeName-Logo">
       <div className="logo">
          <img src="/logo192.png" alt="لوگو فروشگاه" />
        </div>
        <h1>فروشگاه اینترنتی رویا</h1>
      </div>
      

      <nav id="homeProducts">
        <ul>
          <button id="button2"><Link to="/">محصولات</Link></button>
          <button id="button2"><Link to="/contact">تماس با ما</Link></button>
        </ul>
      </nav>
    </header>

  );
}

export default Header;
