import React from 'react';

import './Contact.css'

function Contact() {
  return (
    <div className='contactUs'>
      <h2>تماس با ما</h2>
      <p>برای اطلاعات بیشتر، با ما تماس بگیرید:</p>
      <ul>
        <li>ایمیل: info@roya24.com</li>
        <li> 
          <div>شماره تماس با ما:</div>
          <div>4196  269 1609 49+</div>
          <div>0842 528 70 93+</div></li>
        ‍<li> <a href='https://chat.whatsapp.com/Ge9E5nkUOWSGRBotYzTYG1'>به گروپ وتساپ ما بپیوندید</a> </li>
        <li><a href='https://www.instagram.com/roya_onlineshopping7/profilecard/?igsh=dnMydXI1ZTRqbjA2'>در انستاگرام ما را فالو کنید</a></li>
        
      </ul>
    </div>
  );
}

export default Contact;
