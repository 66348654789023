import React from 'react';
import ProductList from '../components/ProductList';

function Products() {
  return (
    <div className='productsList'>
      
      <ProductList />
    </div>
  );
}

export default Products;
