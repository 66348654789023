import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

//import { BrowserRouter as Router, Route, Routes, Link } from 'react-dom';

import Products from './pages/Products';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <main>
          <Routes>

            <Route path="/" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
