import React, { lazy } from 'react';
import './ProductCard.css'

function ProductCard({ product }) {
  return (
    <div className="product-card">
      <h3 className="product-title">{product.name}</h3>
      <img className="product-image" src={product.image} alt={product.name} onLoad={lazy}/>
      <div className="product-info">
        <p className="product-price">{product.price}</p>
        <p className='productline-info'>{product.info}</p>
        
      </div>
    </div>
  );
}

export default ProductCard;
