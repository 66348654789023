import React from 'react';
import ProductCard from './ProductCard';

const products = [
  { id: 1, num:'', name: 'سایه چشم هودا بیوتی', price: '۲۵۰ افغانی', image: '/images/P_01.jpeg' },
  { id: 2, num:'', name: 'آبرسان سیراوی', price: '۲۸۰ افغانی', info: 'آبرسان و ساف کننده برای جلد خشک و نورمال', image: '/images/P_02.jpeg' },
  { id: 3, num:'', name: 'پرایمر هودا بیوتی', price: '۲۸۰ افغانی',info: 'یکی از بهترین های هودا بیوتی برای ماندگاری بیشتر میکاپ در صورت',  image: '/images/P_03.jpeg' },
  { id: 4, num:'', name: 'آرایش پاک کن گارنیر', price: '۲۸۰ افغانی',info: 'تمیز کردن منافظ پوست از میکاپ های روزمره',  image: '/images/P_04.jpeg' },
  { id: 5, num:'', name: 'ژل ضد جوش و شستشوی صورت سیراوی ', price: '۲۹۰ افغانی', image: '/images/P_25.jpeg' },
  { id: 6, num:'', name: 'سیروم و شامپوی روزماری', price: '۵۰۰ افغانی', image: '/images/P_06.jpeg' },
  { id: 7, num:'', name: 'جلای چشم مس روز', price: 'فی عدد ۶۰ افغانی', image: '/images/P_07.jpeg' },
  { id: 8, num:'', name: 'شوینده و سفید کننده صورت و بدن', price: '۳۰۰ افغانی', image: '/images/P_08.jpeg' },
  { id: 9, num:'', name: 'ماسک حذف جوش های سر سیاه', price: '۳۰۰ افغانی', image: '/images/P_09.jpeg' },
  { id: 10, num:'', name: 'سیروم ناخن موالا', price: '۲۵۰ افغانی', image: '/images/P_10.jpeg' },
  { id: 11, num:'', name: 'چسپ پاک کننده منافظ بینی لنبینا', price: '۲۵۰ افغانی', image: '/images/P_11.jpeg' },
  { id: 12, num:'', name: 'بادی واش لیفوشا', price: '۲۵۰ افغانی', image: '/images/P_12.jpeg' },
  { id: 13, num:'', name: 'اسکرپ لیویی لوریال', price: '۳۰۰ افغانی',info: 'پاک و شفاف کننده صورت', image: '/images/P_13.jpeg' },
  { id: 14, num:'', name: 'ماسک موی کارسیل', price: 'فی دانه ۴۰۰ افغانی', image: '/images/P_14.jpeg' },
  { id: 16, num:'', name: 'سرخی شیگلم', price: 'فی دانه ۱۵۰ افغانی', image: '/images/P_16.jpeg' },
  { id: 17, num:'', name: 'ماسک لب', price: 'فی دانه ۵۰ افغانی', image: '/images/P_17.jpeg' },
  { id: 18, num:'', name: 'سیروم اکسیس وای', price: '۴۵۰ افغانی', image: '/images/P_18.jpeg' },
  { id: 19, num:'', name: 'کریم مرطوب کننده بیواکوا', price: '۲۵۰ افغانی', image: '/images/P_19.jpeg' },
  { id: 20, num:'', name: 'ژل شستشو کف کریم سیراوی', price: '۲۷۰ افغانی', image: '/images/P_20.jpeg' },
  { id: 21, num:'', name: 'ماسک دور چشم دیسار', price: '۳۰۰ افغانی', image: '/images/P_21.jpeg' },
  { id: 22, num:'', name: 'واسلین سفید کننده', price: '۲۲۰ افغانی', image: '/images/P_22.jpeg' },
  { id: 23, num:'', name: 'کریم منرالی ضد آفتاب', price: '۲۴۰ افغانی', image: '/images/P_23.jpeg' },
  { id: 24, num:'', name: 'آرایش پاک کن گارنیر', price: '۲۸۰ افغانی', image: '/images/P_24.jpeg' },
  { id: 26, num:'', name: 'کریم ترک شکم', price: '۲۵۰ افغانی', image: '/images/P_26.jpeg' },
 // { id: 27, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_27.jpeg' },
  { id: 28, num:'', name: 'کریم سفید کننده بروکامین کوریایی', price: '۳۰۰ افغانی', image: '/images/P_28.jpeg' },
  { id: 29, num:'', name: 'بسته سنو بلیج و سکرب مویام', price: '۴۰۰ افغانی', image: '/images/P_29.jpeg' },
  { id: 30, num:'', name: 'کریم دست و پای', price: '۲۰۰ افغانی', image: '/images/P_31.jpeg' },
  { id: 31, num:'', name: 'اسپری بعد میکاپ', price: '۲۵۰ افغانی', image: '/images/P_32.jpeg' },
  { id: 32, num:'', name: 'روغن تقویه موی لنبینا', price: '۲۵۰ افغانی', image: '/images/P_33.jpeg' },
 // { id: 33, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_34.jpeg' },
 // { id: 34, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_35.jpeg' },
  { id: 35, num:'', name: 'سنو بلیج مویام', price: '۲۵۰ افغانی', image: '/images/P_36.jpeg' },
  { id: 1, num:'', name: 'سرخی نارس', price: '۲۵۰ افغانی', image: '/images/P_37.jpeg' },
  { id: 1, num:'', name: 'میکاپ میلین', price: '۱۹۰ افغانی', image: '/images/P_38.jpeg' },
  { id: 1, num:'', name: 'پودر روی میلین', price: '۱۸۰ افغانی', image: '/images/P_39.jpeg' },
  { id: 1, num:'', name: 'سکرب مویام', price: '۲۵۰ افغانی', image: '/images/P_40.jpeg' },
  { id: 1, num:'', name: 'برس موی', price: 'فی دانه ۲۵۰ افغانی', image: '/images/P_41.jpeg' },
  { id: 1, num:'', name: 'کریم لکه صورت', price: '۲۵۰ افغانی', image: '/images/P_42.jpeg' },
  { id: 1, num:'', name: 'سیروم تقویه مژه', price: '۱۸۰ افغانی', image: '/images/P_43.jpeg' },
 // { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_44.jpeg' },
  { id: 1, num:'', name: 'سیروم جلا کننده صورت کوریایی', price: '۴۵۰ افغانی', image: '/images/P_45.jpeg' },
  // { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_46.jpeg' },
  { id: 1, num:'', name: 'سیروم جلا کننده صورت کوریایی', price: '۸۰۰ افغانی', image: '/images/P_47.jpeg' },
  // { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_48.jpeg' },
  { id: 1, num:'', name: 'بسته کریم شب و روز نوی ویا ', price: '۸۰۰ افغانی', image: '/images/P_49.jpeg' },
  { id: 1, num:'', name: 'ماسک ترمیم کننده صورت هنگام خواب ', price: 'فی دانه ۵۰ بسته(۲۰) ۹۰۰ افغانی ', image: '/images/P_50.jpeg' },
  { id: 1, num:'', name: 'کریم زیر چشم', price: '۴۰۰ افغانی', image: '/images/P_51.jpeg' },
  { id: 1, num:'', name: 'کریم ضد چین و چروک', price: '۳۵۰ افغانی', image: '/images/P_52.jpeg' },
  { id: 1, num:'', name: 'قلم ابرو ۲۴ ساعته', price: '۲۵۰ افغانی', image: '/images/P_53.jpeg' },
  { id: 1, num:'', name: 'قلم ابرو ', price: '۲۵۰ افغانی', image: '/images/P_54.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۵۰ افغانی', image: '/images/P_55.jpeg' },
  { id: 1, num:'', name: 'کریم سفید کننده یانگومی', price: '۴۰۰ افغانی', image: '/images/P_56.jpeg' },
  { id: 1, num:'', name: 'ژل ابروی دیمشو', price: '۲۸۰ افغانی', image: '/images/P_57.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۲۵۰ افغانی', image: '/images/P_58.jpeg' },
  { id: 1, num:'', name: 'کریم روی مردانه زیریلی', price: '۳۸۰ افغانی', image: '/images/P_59.jpeg' },
  { id: 1, num:'', name: 'کریم سفت روی مردانه زیریلی', price: '۳۸۰ افغانی', image: '/images/P_60.jpeg' },
  { id: 1, num:'', name: 'کریم سفید کننده زرچوبه زیریلی', price: '۲۸۰ افغانی', image: '/images/P_61.jpeg' },
  { id: 1, num:'', name: 'کانسیلر ایچدی', price: '۵۰۰ افغانی', image: '/images/P_62.jpeg' },
  { id: 1, num:'', name: 'لبسرین و سرخی بلش', price: '۲۰۰ افغانی', image: '/images/P_63.jpeg' },
  { id: 1, num:'', name: 'سیریلی ۵ در ۱', price: '۴۳۰ افغانی', image: '/images/P_64.jpeg' },
  { id: 1, num:'', name: 'آی لایتر', price: 'فی دانه ۲۲۰ افغانی', image: '/images/P_65.jpeg' },
  { id: 1, num:'', name: 'کریم ضد آفتاب کوریایی', price: '۴۵۰ افغانی', image: '/images/P_66.jpeg' },
  { id: 1, num:'', name: 'کریم هلزون کوریایی', price: '۴۰۰ افغانی', image: '/images/P_67.jpeg' },
  { id: 1, num:'', name: 'سیروم سفید کننده زیر چشم', price: '۲۱۰ افغانی', image: '/images/P_68.jpeg' },
  { id: 1, num:'', name: '۴ در ۱ کریم زیر چشم کوریایی', price: '۲۵۰ افغانی', image: '/images/P_69.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۵۰ افغانی', image: '/images/P_70.jpeg' },
  { id: 1, num:'', name: 'سیروم ویتامین سی یانگومی', price: '۲۵۰ افغانی', image: '/images/P_71.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۸۰ افغانی', image: '/images/P_72.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۶۰ افغانی', image: '/images/P_73.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۴۰ افغانی', image: '/images/P_74.jpeg' },
  { id: 1, num:'', name: 'خط چشم دو سره', price: '۱۷۰ افغانی', image: '/images/P_75.jpeg' },
  { id: 1, num:'', name: 'کانسیلر آی سیوی سی', price: '۱۸۰ افغانی', image: '/images/P_76.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۱۰۰ افغانی', image: '/images/P_77.jpeg' },
  { id: 1, num:'', name: 'کانسیلر آی سیوی سی', price: '۱۸۰ افغانی', image: '/images/P_78.jpeg' },
  { id: 1, num:'', name: 'پرایمر پوپ فییل', price: '۲۹۰ افغانی', image: '/images/P_79.jpeg' },
  { id: 1, num:'', name: 'مژه شان', price: '۱۴۰ افغانی', image: '/images/P_80.jpeg' },
  { id: 1, num:'', name: 'بسته سه عددی کریم دور چشم', price: '۴۸۰ افغانی', image: '/images/P_81.jpeg' },
 // { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_82.jpeg' },
  { id: 1, num:'', name: 'حجم دهنده لب', price: '۲۷۰ افغانی', image: '/images/P_83.jpeg' },
  { id: 1, num:'', name: 'ماسک خواب با یخ', price: 'فی دانه ۴۰ یک بسته ۷۵۰ افغانی', image: '/images/P_84.jpeg' },
  { id: 1, num:'', name: 'ناخن', price: '۲۴۰ افغانی', image: '/images/P_85.jpeg' },
  { id: 1, num:'', name: 'کریم روی هلزون', price: '۲۵۰ افغانی', image: '/images/P_86.jpeg' },
  { id: 1, num:'', name: 'کریم ضد آفتاب', price: '۲۰۰ افغانی', image: '/images/P_87.jpeg' },
//  { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_88.jpeg' },
//  { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_89.jpeg' },
  { id: 1, num:'', name: 'لبسرین', price: 'فی دانه ۱۸۰ افغانی', image: '/images/P_90.jpeg' },
//  { id: 1, num:'', name: 'کانسیلر ام سی دی', price: '۴,۵۰۰ افغانی', image: '/images/P_91.jpeg' },
//  { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_92.jpeg' },
//  { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_93.jpeg' },
//  { id: 1, num:'', name: 'کریم سفید کننده', price: '۴,۵۰۰ افغانی', image: '/images/P_94.jpeg' },
  { id: 1, num:'', name: 'بکس لوازم آرایشی', price: '۳۰۰ افغانی', image: '/images/P_95.jpeg' },
  { id: 1, num:'', name: 'پرایمر آی سی وی سی', price: '۳۰۰ افغانی', image: '/images/P_96.jpeg' },


];

function ProductList() {
  return (
    <section className="product-list">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </section>
  );
}

export default ProductList;